// Note: it's important to check auth for login/register pages, prevent access to these pages when already authed
import { defineNuxtMiddleware } from '@nuxtjs/composition-api'
import { keyOfAuthToken } from '@/composables/useAuth'

export default defineNuxtMiddleware(({ app, redirect }) => {
  const token = app.$storage.getCookie(keyOfAuthToken)

  if (token) {
    // when my account dashboard page is already then redirect to my account dashboard page
    redirect(app.localePath({ name: 'index' }))
  }
})
